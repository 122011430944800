import { clone } from 'lodash';
import { Container, Sprite } from '@/pixi';
import { Component } from '@/components/shared/Component';
import { Spinner } from '@/utility/Spinner';

export class Wheel extends Component {
  constructor(params) {
    const options = clone(params);
    super('wheel', options);

    const numbersCount = options.numbers.length;
    const numbersRotateSegment = 360 / numbersCount;
    const circleSprite = new Sprite(options.assets.circle.resource);
    const centerSprite = new Sprite(options.assets.center.resource);
    const edgeSprite = new Sprite(options.assets.edge.resource);
    const stopperSprite = new Sprite(options.assets.stopper.resource);
    const rotateContainer = new Container();

    circleSprite.anchor.set(0.5);
    centerSprite.anchor.set(0.5);
    edgeSprite.anchor.set(0.5);
    stopperSprite.anchor.set(0.5, 0.4);
    stopperSprite.position.y = -circleSprite.height * 0.5;

    rotateContainer.addChild(circleSprite);

    this.container.addChild(rotateContainer);
    this.container.addChild(centerSprite);
    this.container.addChild(edgeSprite);
    this.container.addChild(stopperSprite);

    options.numbers.forEach((number, numberIndex) => {
      const numberContainer = new Container();
      const numberCharList = number.toString();

      numberCharList.split('').forEach((numberCharItem) => {
        const numberChar = numberCharItem.charCodeAt(0);
        const numberTexture = options.assets.numbers.resource.textures[`${numberChar}.png`];
        const numberSprite = new Sprite(numberTexture);
        const numberBounds = numberContainer.getBounds();

        numberSprite.anchor.set(0, 1);
        numberSprite.x = numberBounds.width;
        numberContainer.addChild(numberSprite);
      });

      const numberContainerBounds = numberContainer.getBounds();
      const numberPivotY = options.numberPivotY || 1;

      numberContainer.y = (((circleSprite.height * 0.385)) * Math.sin((numberIndex * Math.PI * 2) / numbersCount));
      numberContainer.x = ((circleSprite.width * 0.385)) * Math.cos((numberIndex * Math.PI * 2) / numbersCount);
      numberContainer.pivot.set(numberContainerBounds.width * 0.5, -numberContainerBounds.height * numberPivotY);
      numberContainer.angle = (numberIndex * numbersRotateSegment) + 90;

      rotateContainer.addChild(numberContainer);
    });

    this.rotateContainer = rotateContainer;
    this.numbersRotateSegment = numbersRotateSegment;
    this.data = { angle: 270 };
    this.spin = new Spinner().spin.bind(this);

    /*
    Rotate after resize because rotation will
    give different bounding box dimensions.
    */
    circleSprite.angle = numbersRotateSegment * 0.5;
    rotateContainer.angle = this.data.angle;

    /*
    Start playing ambient music.
    */
    this.audio.play(options.assets.soundStart);
    this.audio.play(options.assets.soundAmbient, { loop: true, volume: 0.5 });

    this.container.pivot.set(-this.container.width / 2, -this.container.height / 2);
    this.resize();
  }
}
