import animate from 'gsap';

export class Spinner {
  spin(number, durationInSec) {
    const component = this;
    const duration = durationInSec || 5;
    const numberPreviousSegment = component.data.previousSegment || 0;
    const numberCurrentSegment = component.options.numbers.findIndex((n) => n === number);
    const numberRangeSegment = Math.abs(numberCurrentSegment - numberPreviousSegment);
    const numberSegment = numberCurrentSegment > numberPreviousSegment ? component.options.numbers.length - numberRangeSegment : numberRangeSegment;
    const numberOfSpins = Math.ceil(duration / 2.5);

    if (numberSegment >= 0) {
      const angle = component.data.angle + (numberSegment * component.numbersRotateSegment) + (numberOfSpins * 360);

      animate.to(component.rotateContainer, {
        duration: 0.5,
        angle: component.rotateContainer.angle - component.numbersRotateSegment,
        onComplete() {
          animate.to(component.rotateContainer, {
            duration,
            ease: 'power2.out',
            angle,
            onStart() {
              component.audio.play(component.options.assets.soundSpin);
            },
            onComplete() {
              component.audio.play(component.options.assets.soundDraw);
              component.data.angle = angle;
              component.data.previousSegment = numberCurrentSegment;
            },
          });
        },
      });
    }
  }
}
