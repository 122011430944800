const gameMapper = {
  CherryXmass: 'CherryBlast',
  GoldOfEgyptDice: 'GoldOfEgypt',
};

export const Game = async (gameName) => {
  try {
    const game = gameMapper[gameName] ? gameMapper[gameName] : gameName;
    const gameModule = await import(`./${game}.js`);
    return gameModule[game];
  } catch (e) {
    const gameModule = await import('./Slot');
    return gameModule.Slot;
  }
};

export { Wheel } from '@/games/Wheel';
