import './shims/globalThis';
import './utility/gsapConfig';

import Lodash from 'lodash';
import Animate from 'gsap';
import { Howl as Sound, Howler as Sounds } from 'howler';
import * as Pixi from './pixi';
import { audioParser } from './parsers';
import { App } from './app';
import * as Model from './models';
import * as Api from './api';
import * as Component from './games';
import * as Utility from './utility/Utility';
import * as Translations from './translations';

/*
This setting causes small x/y shifts in rotation animation.
Uncomment to make animation more performant in apple devices.
Pixi.settings.ROUND_PIXELS = Pixi.utils.isMobile.apple.device;
*/
Pixi.settings.RENDER_OPTIONS.hello = false;
Sounds.html5PoolSize = 20;

Pixi.extensions.add(audioParser);

const IsMobile = Pixi.utils.isMobile;

export {
  Animate,
  Api,
  App,
  Component,
  IsMobile,
  Lodash,
  Model,
  Pixi,
  Sound,
  Sounds,
  Utility,
  Translations,
};
