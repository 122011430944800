class Logger {
  constructor(name, isEnabled) {
    this.name = name;
    this.isEnabled = isEnabled;
  }

  log(logType, message, data) {
    if (this.isEnabled) {
      const label = `[${this.name}]${message}`;

      if (data) {
        console[logType.toLowerCase()](label, data);
      } else {
        console[logType.toLowerCase()](label);
      }
    }
  }
}

export default new Logger('CasinoWebGL', true);
