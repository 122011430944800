import { camelCase } from 'lodash';
import { Container } from '@/pixi';
import { Resizer } from '@/utility/Resizer';
import { Audio } from '@/utility/Audio';

export class Component {
  constructor(name, options) {
    this.name = camelCase(name);
    this.options = options;
    this.container = new Container();
    this.resizer = new Resizer(this);
    this.audio = new Audio();
  }

  resize() {
    this.resizer.resize();
  }

  getSize() {
    return this.resizer.getSize();
  }
}
