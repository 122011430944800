import { isFunction } from 'lodash';

export class Resizer {
  constructor(context) {
    this.context = context;

    /*
    Immediatelly hook resize mechanism to component.
    */
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.resize();
      });
    });

    if (window.screen && window.screen.orientation) {
      window.screen.orientation.onchange = () => {
        setTimeout(() => {
          this.resize();
        }, 100);
      };
    } else {
      window.addEventListener('orientationchange', () => {
        setTimeout(() => {
          this.resize();
        }, 100);
      });
    }
  }

  resize() {
    /*
    Resize is called immediatelly in component. This is
    why we are setting original size on first resize.
    */
    if (this.context.originalSize === undefined) {
      const bounds = this.context.container.getBounds();
      this.context.originalSize = {
        width: bounds.width,
        height: bounds.height,
      };
    }

    let position;
    if (this.context.options.position) {
      position = this.context.options.position.bind(this.context)();
    } else {
      position = {
        x: 0,
        y: 0,
      };
    }

    this.setScale();
    this.context.container.position.set(position.x, position.y);

    if (isFunction(this.context.onAfterResize)) {
      this.context.onAfterResize();
    }
  }

  getScale() {
    const size = this.context.options.size.bind(this.context)();
    const scale = size.width / this.context.originalSize.width;

    return scale;
  }

  getSize() {
    this.setScale();

    const bounds = this.context.container.getBounds();

    return {
      width: bounds.width,
      height: bounds.height,
    };
  }

  setScale() {
    const scale = this.getScale();
    this.context.container.scale.set(scale);
  }
}
