import defaultTranslations from './locales/en';

const defaultLocale = 'en';
let usedTranslations;

export async function getTranslations(locale = defaultLocale) {
  if (locale !== defaultLocale) {
    try {
      const fileName = locale === 'sr' ? 'sr-Latn' : locale;
      const translations = await import(`./locales/${fileName}`);

      usedTranslations = Object.assign(defaultTranslations, translations.default);
    } catch {
      usedTranslations = defaultTranslations;
    }
  } else {
    usedTranslations = defaultTranslations;
  }

  return usedTranslations;
}

export function getTranslation(translationKey) {
  return usedTranslations?.[translationKey] || defaultTranslations[translationKey];
}
