import { endsWith } from 'lodash';
import { Howl as Sound } from 'howler';
import { utils } from '../pixi';

const audioParser = {
  extension: {
    type: 'load-parser',
    priority: 'high',
  },
  test(url) {
    return endsWith(url, 'mp3');
  },
  async load(url, asset) {
    const sound = new Sound({
      src: [url],
      html5: utils.isMobile.apple.device,
      preload: true,
    });

    return new Promise((resolve, reject) => {
      sound.once('load', () => {
        resolve(sound);

        if (asset.data && asset.data.loaded) {
          asset.data.loaded(undefined, sound);
        }
      });

      sound.once('loaderror', (error) => {
        reject(error);

        if (asset.data && asset.data.loaded) {
          asset.data.loaded(error, sound);
        }
      });
    });
  },
};

export {
  audioParser,
};
