import { defaultTo, merge } from 'lodash';
import { Howler as Sounds } from 'howler';
import { registerEventListener, triggerEvent } from './Utility';

export class Audio {
  constructor(options) {
    this.setOptions(options);
    this.setListeners();
    this.isSoundOff = false;
  }

  setOptions(options = {}) {
    this.isSoundOff = defaultTo(options.mute, false);

    this.options = merge({
      mute: this.isSoundOff,
      loop: false,
      rate: 1,
      volume: 1,
    }, options);
  }

  setListeners() {
    const source = 'AudioUtility';

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        if (this.isSoundOff === false) {
          Sounds.mute(false);
        }
      } else {
        Sounds.mute(true);
      }
    });

    registerEventListener('SetSound', (event) => {
      this.mute(event.detail.isSoundOff);
    }, source);
  }

  mute(isSoundOff) {
    if (this.isSoundOff !== isSoundOff) {
      this.isSoundOff = isSoundOff;
      this.options.mute = isSoundOff;

      Sounds.mute(isSoundOff);

      triggerEvent('MuteChanged', {
        isSoundOff,
      });
    }
  }

  toggleMute() {
    this.mute(!this.isSoundOff);
  }

  play(asset, options = {}, instanceId = undefined) {
    if (this.options.mute || this.isSoundOff) {
      return undefined;
    }

    if (asset && asset.resource) {
      try {
        const rate = defaultTo(options.rate, this.options.rate);
        const loop = defaultTo(options.loop, this.options.loop);
        const volume = defaultTo(options.volume, this.options.volume);

        asset.resource.rate(rate);
        asset.resource.loop(loop);
        asset.resource.volume(volume);

        const playInstanceId = asset.resource.play(instanceId);

        return playInstanceId;
      } catch (error) {
        console.warn('[GamesWebgl]SoundPlayError', error.message);
      }
    }

    return undefined;
  }

  stop(asset, instanceId, options = {}) {
    if (asset && asset.resource) {
      try {
        const rate = defaultTo(options.rate, this.options.rate);

        asset.resource.rate(rate);

        if (instanceId) {
          asset.resource.stop(instanceId);
        } else {
          asset.resource.stop();
        }
      } catch (error) {
        console.warn('[GamesWebgl]SoundStopError', error.message);
      }
    }
  }

  pause(asset, instanceId) {
    if (asset && asset.resource) {
      try {
        asset.resource.pause(instanceId);
      } catch (error) {
        console.warn('[GamesWebgl]SoundPauseError', error.message);
      }
    }
  }
}
